import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AccountService } from '../providers/account.service';
import { RolesPermissionService } from '../providers/authentication/roles.service';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keyCloakService: KeycloakService,
        private authenService: AccountService,
        private rolesPermissionService: RolesPermissionService
    ) {
        super(router, keyCloakService);
    }

    public async isAccessAllowed(route: any, state: RouterStateSnapshot): Promise<boolean> {
        const everyRoles: string[] = route.routeConfig.everyRoles || [];
        const someRoles: string[] = route.routeConfig.someRoles || [];

        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keyCloakService.login({
                redirectUri: window.location.origin + state.url,
            });
        }

        // Check Roles
        let userRoles = this.authenService.getUserRoles();
        let isPassEvery: boolean = false;
        let isPassSome: boolean = false;

        // Check everyRoles
        if (Array.isArray(everyRoles) && everyRoles.length > 0)
            isPassEvery = everyRoles.every((role) => userRoles.includes(role));
        else isPassEvery = true;

        // Check someRoles
        if (isPassEvery)
            if (Array.isArray(someRoles) && someRoles.length > 0)
                isPassSome = someRoles.some((role) => userRoles.includes(role));
            else isPassSome = true;

        if (isPassEvery && isPassSome) {
            this.authenService.updateUserdata();
            this.rolesPermissionService.changeForRole(userRoles);
            return true;
        }
        await Swal.fire('Tài khoản không được cấp phép!', '', 'error');
        this.keyCloakService.logout();
        return false;
    }
}
