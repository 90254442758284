import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class FilesDownloadedService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getAll() {
        let final_metadata: any = this.accountService.getMetadata();
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Readv1 +
                        '/files-downloaded/get-all?apikey=' +
                        this.accountService.apikey,
                    {
                        ma_don_vi: final_metadata.ma_don_vi,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getAllByUID() {
        let final_metadata: any = this.accountService.getMetadata();
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Readv1 + '/files-downloaded/get-all-by-uid',
                    {},
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async markAsRead(filename: string, ma_don_vi: string = '') {
        let metadata = this.accountService.getMetadata();
        metadata.mat_khau_don_vi = '';
        if (!ma_don_vi) ma_don_vi = metadata.ma_don_vi;
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/files-downloaded/mark-as-read',
                {
                    metadata: metadata,
                    ma_don_vi: ma_don_vi,
                    filename: filename,
                },
                {
                    headers: { apikey: this.accountService.apikey },
                }
            )
            .toPromise();
    }

    async markAsReadByUID(filename: string) {
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/files-downloaded/mark-as-read-by-uid',
                {
                    filename: filename,
                },
                {
                    headers: { apikey: this.accountService.apikey },
                }
            )
            .toPromise();
    }

    async markAllAsReadByUID() {
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/files-downloaded/mark-all-as-read-by-uid',
                {},
                {
                    headers: { apikey: this.accountService.apikey },
                }
            )
            .toPromise();
    }
}
