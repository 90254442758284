import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2';
import { HostnameService } from './hostname.service';

export interface AuthencationResponse {
    code: string;
    token: string;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
    private _attributes: any = {};
    private _profile: any;
    private _subject: string = '';

    public get apikey() {
        if (Array.isArray(this._attributes?.apikey) && this._attributes?.apikey[0]) return this._attributes?.apikey;
        return 'user_key';
    }

    public get subject() {
        return this._subject;
    }

    public get profile() {
        return this._profile;
    }

    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        protected readonly keyCloakService: KeycloakService
    ) {}

    getMetadata() {
        let final_metadata: { ma_don_vi: string; mat_khau_don_vi: string } = {
            ma_don_vi: '',
            mat_khau_don_vi: '',
        };
        if (this._attributes.ma_don_vi) final_metadata.ma_don_vi = this._attributes.ma_don_vi[0];
        if (this._attributes.mat_khau_don_vi) final_metadata.mat_khau_don_vi = this._attributes.mat_khau_don_vi[0];
        return final_metadata;
    }

    getAvatarFileName() {
        if (Array.isArray(this._attributes.avatar) && this._attributes.avatar.length > 0) {
            return this._attributes.avatar[0];
        }
        return undefined;
    }

    changeUserInfo(email = '', firstName = '', lastName = '') {
        let metadata = this.getMetadata();
        return this.httpClient
            .put<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/account-info/change-info',
                {
                    metadata: metadata,
                    account_data: { email: email, firstName: firstName, lastName: lastName },
                    old_account_data: {
                        email: this._profile.email,
                        firstName: this._profile.firstName,
                        lastName: this._profile.lastName,
                    },
                    _id: this.subject,
                },
                {
                    headers: {
                        apikey: this.apikey,
                    },
                }
            )
            .toPromise();
    }

    async updateUserProfile() {
        let profile: any = await this.keyCloakService.loadUserProfile(true);
        this._profile = profile;
        this._attributes = profile.attributes;
    }

    updateUserdata() {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let userRole = [];

        if (parseToken?.resource_access['webapp-tttt']) {
            userRole = parseToken?.resource_access['webapp-tttt'].roles;
        }

        this._attributes = keyCloakInstance.profile.attributes;
        this._profile = keyCloakInstance.profile;
        this._subject = keyCloakInstance.subject;
    }

    getUserRoles(webapp: string = 'webapp-tttt'): string[] {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let userRoles: string[] = [];
        if (parseToken?.resource_access[webapp]) {
            userRoles = parseToken?.resource_access[webapp].roles;
        }
        return userRoles;
    }

    requestChangePassword() {
        let metadata: any = {
            ma_don_vi: this.getMetadata().ma_don_vi,
        };
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/account-info/reset-keycloak-password',
                { metadata: metadata },
                {
                    headers: {
                        apikey: this.apikey,
                    },
                }
            )
            .toPromise();
    }

    getAPIKey() {
        return this.apikey;
    }

    updateKeycloakAccounts() {
        return this.httpClient
            .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/account-info/update-accounts-keycloak', {
                headers: { apikey: this.getAPIKey() },
            })
            .toPromise();
    }
}
