import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/providers/language.service';
import { AccountService } from 'src/app/providers/account.service';
import { KeycloakService } from 'keycloak-angular';
import { AccountAvatarService } from 'src/app/providers/avatar.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-vertical-header',
    templateUrl: './vertical-header.component.html',
    styleUrls: [],
})
export class VerticalAppHeaderComponent {
    public config: PerfectScrollbarConfigInterface = {};

    public selectedLanguage: any = {
        language: 'Tiếng Việt',
        code: 'vn',
        type: 'VN',
        icon: 'vn',
    };

    public languages: any[] = [
        {
            language: 'English',
            code: 'en',
            type: 'US',
            icon: 'us',
        },
        {
            language: 'Tiếng Việt',
            code: 'vn',
            type: 'VN',
            icon: 'vn',
        },
    ];

    avatarLink: string = '';

    constructor(
        private translate: TranslateService,
        private languageService: LanguageService,
        private keycloakService: KeycloakService,
        private accountAvatarService: AccountAvatarService,
        private accountService: AccountService,
        private router: Router
    ) {
        translate.setDefaultLang('vn');
        // this.changeLanguage("vn");

        if (this.accountAvatarService.AvatarLink == '') this.avatarLink = './assets/images/users/default.png';
        else this.avatarLink = this.accountAvatarService.AvatarLink;

        this.accountAvatarService.AvatarLinkSub.subscribe((value: string) => {
            if (value == '') this.avatarLink = './assets/images/users/default.png';
            else this.avatarLink = value;
        });
    }

    async ngAfterViewInit() {
        await this.accountAvatarService.updateAvatarLink();
    }

    isShowUpdateKeycloakAccounts() {
        let userRoles: string[] = this.accountService.getUserRoles();
        if (!Array.isArray(userRoles) || userRoles.length <= 0) return false;
        return userRoles.includes('update_keycloak_account');
    }

    changeLanguage(lang: any): void {
        this.translate.use(lang.code);
        this.selectedLanguage = lang;
        this.languageService.setLanguage(lang.code);
    }

    logout() {
        this.keycloakService.logout();
    }

    accountInfo() {
        this.router.navigateByUrl('/user/account/editaccount');
    }

    async updateKeycloakAccounts() {
        const { isConfirmed } = await Swal.fire({
            title: this.translate.instant('dialog.ban_co_chac_khong'),
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true,
        });
        if (!isConfirmed) return;

        if (Swal.isVisible()) Swal.close();
        Swal.fire(this.translate.instant('dialog.dang_xu_ly'));
        Swal.showLoading();

        let result = await this.accountService.updateKeycloakAccounts();
        if (result.status == 'error') {
            Swal.fire(
                this.translate.instant('dialog.loi'),
                this.translate.instant('return_msg.' + result.msg, 'error')
            );
            return;
        }

        Swal.close();
        Swal.fire(
            this.translate.instant('dialog.thanh_cong'),
            `Updated ${result.update} accounts, Added ${result.add} accounts`,
            'success'
        );
    }
}
