import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

export const VAN_PHONG_CODE = new Set(['103']);
export const VAN_PHONG_CODES = new Set(['103', '110', '111', '112', '106', '113', '37']);
export const VAN_PHONG_EDITABLE_CODES = new Set(['110', '111', '112', '106', '113', '37']);
export const DT_CODES = new Set(['110']);
export const KHCN_CODES = new Set(['111']);
export const DN_PTDA_CODES = new Set(['112']);
export const TTCB_CODES = new Set(['106']);
export const CTSV_CODES = new Set(['113']);
export const KDCLGD_CODES = new Set(['37']);

@Injectable({ providedIn: 'root' })
export class VanPhongCodesService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}
}
