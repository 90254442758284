import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class ProcessUploadStatusService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getActiveStatus(type: string, ma_don_vi: string = '') {
        let final_metadata: any = this.accountService.getMetadata();
        if (!ma_don_vi) ma_don_vi = '';
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/process-upload-status/get-active-status',
                {
                    metadata: final_metadata,
                    ma_don_vi: ma_don_vi,
                    type: type,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async markASReadById(id: string) {
        let final_metadata: any = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/process-upload-status/mark-as-read-by-id',
                {
                    metadata: final_metadata,
                    id: id,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }
}
