import { Routes } from '@angular/router';

import { AuthGuard } from './helper/auth.guard';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { ErrorPageForMainComponent } from './authentication/error-main-component/error.component';

export const AppRoutes: any = [
    {
        path: '',
        someRoles: [
            'admin',
            'donvi.ctsv.chuyenvien',
            'donvi.ctsv.lanhdaophong',
            'donvi.ctsv.lanhdaodonvi',
            'donvi.dnptda.chuyenvien',
            'donvi.dnptda.lanhdaophong',
            'donvi.dnptda.lanhdaodonvi',
            'donvi.dt.chuyenvien',
            'donvi.dt.lanhdaophong',
            'donvi.dt.lanhdaodonvi',
            'donvi.khcn.chuyenvien',
            'donvi.khcn.lanhdaophong',
            'donvi.khcn.lanhdaodonvi',
            'donvi.khtc.hntc.ketoanvien',
            'donvi.khtc.hntc.ketoantruong',
            'donvi.khtc.hntc.lanhdaodonvi',
            'donvi.qldaqt.chuyenvien',
            'donvi.qldaqt.lanhdaophong',
            'donvi.qldaqt.lanhdaodonvi',
            'donvi.tccb.chuyenvien',
            'donvi.tccb.lanhdaophong',
            'donvi.tccb.lanhdaodonvi',
            'donvi.tdkt.chuyenvien',
            'donvi.tdkt.lanhdaophong',
            'donvi.tdkt.lanhdaodonvi',
            'donvi.ttktcldt.chuyenvien',
            'donvi.ttktcldt.lanhdaophong',
            'donvi.ttktcldt.lanhdaodonvi',
            'donvi.ttpc.chuyenvien',
            'donvi.ttpc.lanhdaophong',
            'donvi.ttpc.lanhdaodonvi',
            'donvi.vp.chuyenvien',
            'donvi.vp.lanhdaophong',
            'donvi.vp.lanhdaodonvi',
            'donvi.khcn.chuyenvien',
            'donvi.khcn.lanhdaophong',
            'donvi.khcn.lanhdaodonvi',
            'donvi.khdt.lanhdaophong',
            'donvi.khdt.lanhdaodonvi',

            //
            'test_synt_base',
        ],
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'user/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () =>
                    import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '**',
        component: AppBlankComponent,
        children: [
            {
                path: '',
                component: ErrorPageForMainComponent,
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'authentication/404',
    },
];
