import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItems } from './providers/authentication/menu-items.service';
import { GeneralDataService } from './providers/general-data.service';
import { QlvbVbNhanService } from './providers/quan_li_van_ban/vb_nhan.service';
import { KhtcBctcTT107Service } from './providers/bao-cao-ban-khtc-bctc/khtc-bctc.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './providers/language.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
    // Translate
    private languageSub!: Subscription;

    updateIsHasNewID: any | undefined;

    constructor(
        private qlvbVbNhanService: QlvbVbNhanService,
        private generalDataService: GeneralDataService,
        private menuItems: MenuItems,
        private khtcBctcTT107Service: KhtcBctcTT107Service,
        private languageService: LanguageService,
        private translate: TranslateService,
        private cookieService: CookieService
    ) {
        this.translate.use(this.languageService.language);
        this.languageSub = this.languageService.languageSub.subscribe((newLanguage: string) => {
            this.translate.use(this.languageService.language);
            this.cookieService.set('language', this.languageService.language);
        });
        translate.setDefaultLang('vn');
    }

    ngOnInit() {
        this.generalDataService.fetch();
    }

    async ngAfterViewInit() {
        await this.updateIsHasNew();
        // setTimeout(() => this.updateIsHasNew(), 5000);
        this.generalDataService.fetch();
        this.khtcBctcTT107Service.fetch();

        let savedLanguage: string = this.cookieService.get('language');
        if (!savedLanguage) savedLanguage = 'vn';
        this.languageService.language = savedLanguage;
    }
    ngOnDestroy(): void {
        if (this.updateIsHasNewID) clearInterval(this.updateIsHasNewID);
        if (this.languageSub) this.languageSub.unsubscribe();
    }

    async updateIsHasNew() {
        let isHasNew = await this.qlvbVbNhanService.isHasNew();
        this.menuItems.updateReceiverFileInternal(isHasNew);
    }
}
