<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"> </i>
</button>
<mat-menu #flags="matMenu" class="mymegamenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
        <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
        <span>{{ lang.language }}</span>
    </button>
</mat-menu>

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <img [src]="avatarLink" alt="user" class="profile-pic" />
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item (click)="accountInfo()">
        <mat-icon>account_box</mat-icon>
        <span>{{ 'thong_tin_tai_khoan' | translate }}</span>
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'dang_xuat' | translate }}</span>
    </button>

    <ng-container *ngIf="isShowUpdateKeycloakAccounts()">
        <div style="border-bottom: 1px solid rgba(0,0,0,0.12)">&nbsp;</div>
        <button mat-menu-item (click)="updateKeycloakAccounts()">
            <mat-icon>update</mat-icon>
            <span>{{ 'update_keycloak_account' | translate }}</span>
        </button>
    </ng-container>
</mat-menu>

<!-- <button (click)="logout()" mat-icon-button class="m-r-5">
    <mat-icon>logout</mat-icon>
</button> -->
