// CONSTABCE
export const MCODES = {
    dashboard: 'dashboard', // Bảng điều khiển
    qlvb: 'qlvb', // Quản lý văn bản
    qlcctc: 'qlcctc', // Quản lý cơ cấu tổ chức
    qltssv: 'qltssv', // QL tuyển sinh sinh viên
    qltshv: 'qltshv', // QL tuyển sinh học viên
    qlhscbgv: 'qlhscbgv', // QL hồ sơ CBGV
    qlhssv: 'qlhssv', // QL hồ sơ SV
    qlhshv: 'qlhshv', // QL hồ sơ HV
    qltnsv: 'qltnsv', // QL tốt nghiệp SV
    qltnhv: 'qltnhv', // QL tốt nghiệp HV
    qlmn: 'qlmn', // QL Mở ngành
    bcth: 'bcth', // Báo cáo tổng hợp
    bcth_bc: {
        vp: 'bcth_bcvp', // Văn phòng
        ttpc: 'bcth_bcttpc', // Thanh tra pháp chế
        tdkt: 'bcth_bctdkt', // Thi đua khen thưởng
        tccb: 'bcth_bctccb', // Tổ chức cán bộ
        dt: 'bcth_bcdt', // Đào tạo

        khtc_hntc: 'bcth_bckhtc_hntc', // Hội nghị tài chính
        khtc_bctc: 'bcth_bckhtc_bctc', // Báo cáo tài chính 107
        khtc_bctc_tt99: 'bcth_bckhtc_bctc_tt99', // Báo cáo tài chính 99
        khtc_bcqt: 'bcth_bckhtc_bcqt', // Báo cáo quyết toán
        khtc_bcqtr: 'bcth_bckhtc_bcqtr', // Báo cáo quản trị
        khtc_bc_du_toan: 'khtc_du_toan', // Ban KHTC báo cáo dự toán

        khcn: 'bcth_bckhcn', // Khoa học công nghệ
        dnptda: 'bcth_bcdnptda', // Đối ngoại phát triển dự án
        ctsv: 'bcth_bcctsv', // Công tác sinh viên
        qldaqt: 'bcth_bcqldaqt', // Quản lý dự án quốc tế

        khdt: 'bcth_khdt', // Kế hoạch đầu tư

        reportkhtc: 'reportkhtc', // OLD KHTC
    },
    luutru: 'luutru', // Lưu trữ
    tienich: 'tienich', // Tiện ích
    taikhoan: 'taikhoan', // Tài khoản

    // qlhscbgv_synt: 'qlhscbgv_synt', // QL hồ sơ CBGV
    khao_sat: 'khao_sat', // QL hồ sơ CBGV

    ctdt: 'ctdt', // Chương trình đào tạo

    //
    synt_base: 'synt_base',
    version_staff: 'version_staff',
    version_learner: 'version_learner',
    version_student: 'version_student',
};

// ROLES

// prettier-ignore
export const MENU_ITEMS_ROLES: Map<string, string[]> = new Map([
    ['test_synt_base', [
        MCODES.synt_base, 
        MCODES.version_staff,
        MCODES.version_learner,
        MCODES.version_student,
    ]],

    ['admin', [
        MCODES.dashboard, MCODES.qlvb, MCODES.qlcctc, MCODES.qltssv, MCODES.qltshv, MCODES.qltnsv, MCODES.qltnhv, MCODES.qlmn, 
        MCODES.bcth, MCODES.bcth_bc.vp, MCODES.bcth_bc.ttpc, MCODES.bcth_bc.tdkt, MCODES.bcth_bc.tccb, MCODES.bcth_bc.dt, MCODES.bcth_bc.khtc_hntc, MCODES.bcth_bc.khtc_bctc, MCODES.bcth_bc.khcn, MCODES.bcth_bc.dnptda, MCODES.bcth_bc.ctsv, MCODES.bcth_bc.qldaqt, MCODES.bcth_bc.reportkhtc, 
        MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        // MCODES.qlhscbgv, MCODES.qlhssv, MCODES.qlhshv,
        MCODES.version_staff, MCODES.version_learner, MCODES.version_student,
        // MCODES.bcth_bc.khtc_bc_du_toan,
        MCODES.khao_sat,
    ]],

    // CTSV
    ['donvi.ctsv.chuyenvien', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.ctsv, MCODES.luutru, MCODES.tienich, MCODES.taikhoan,  
        // MCODES.qlhscbgv_synt
        MCODES.khao_sat
    ]],
    ['donvi.ctsv.lanhdaophong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.ctsv, MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        // MCODES.qlhscbgv_synt
        MCODES.khao_sat
    ]],
    ['donvi.ctsv.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.ctsv, MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        // MCODES.qlhscbgv_synt
        MCODES.khao_sat
    ]],

    // TCCB
    ['donvi.tccb.chuyenvien', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.tccb, MCODES.luutru, MCODES.tienich, MCODES.taikhoan,  
        // MCODES.qlhscbgv_synt,
        MCODES.qlcctc, 
        // MCODES.qlhscbgv,
        MCODES.version_staff,
    ]],
    ['donvi.tccb.lanhdaophong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.tccb, MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        // MCODES.qlhscbgv_synt,
        MCODES.qlcctc, 
        // MCODES.qlhscbgv,
        MCODES.version_staff,
    ]],
    ['donvi.tccb.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.tccb, MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        // MCODES.qlhscbgv_synt,
        MCODES.qlcctc, 
        // MCODES.qlhscbgv,
        MCODES.version_staff,
    ]],

    // DNPTDA
    ['donvi.dnptda.chuyenvien', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.dnptda, MCODES.luutru, MCODES.tienich, MCODES.taikhoan,  
        // MCODES.qlhscbgv_synt
    ]],
    ['donvi.dnptda.lanhdaophong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.dnptda, MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        // MCODES.qlhscbgv_synt
    ]],
    ['donvi.dnptda.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.dnptda, MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        // MCODES.qlhscbgv_synt
    ]],

    // KHTC
    ['donvi.khtc.hntc.ketoanvien', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.khtc_hntc, MCODES.luutru, MCODES.tienich, MCODES.taikhoan,  
        MCODES.qlcctc,
        MCODES.bcth_bc.khtc_bctc,
        MCODES.bcth_bc.khtc_bctc_tt99,
        MCODES.bcth_bc.khtc_bcqt,
        MCODES.bcth_bc.khtc_bcqtr,
        MCODES.bcth_bc.khtc_bc_du_toan,
    ]],
    ['donvi.khtc.hntc.ketoantruong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.khtc_hntc, MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        MCODES.qlcctc,
        MCODES.bcth_bc.khtc_bctc,
        MCODES.bcth_bc.khtc_bctc_tt99,
        MCODES.bcth_bc.khtc_bcqt,
        MCODES.bcth_bc.khtc_bcqtr,
        MCODES.bcth_bc.khtc_bc_du_toan,
    ]],
    ['donvi.khtc.hntc.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.khtc_hntc, MCODES.luutru, MCODES.tienich, MCODES.taikhoan, 
        MCODES.qlcctc,
        MCODES.bcth_bc.khtc_bctc,
        MCODES.bcth_bc.khtc_bctc_tt99,
        MCODES.bcth_bc.khtc_bcqt,
        MCODES.bcth_bc.khtc_bcqtr,
        MCODES.bcth_bc.khtc_bc_du_toan,
    ]],

    // DAO TAO 
    ['donvi.dt.chuyenvien', [
        MCODES.dashboard, MCODES.ctdt, MCODES.luutru, MCODES.taikhoan,  
         MCODES.bcth, MCODES.bcth_bc.dt
    ]],
    ['donvi.dt.lanhdaophong', [
        MCODES.dashboard, MCODES.ctdt, MCODES.luutru, MCODES.taikhoan, 
         MCODES.bcth, MCODES.bcth_bc.dt
    ]],
    ['donvi.dt.lanhdaodonvi', [
        MCODES.dashboard, MCODES.ctdt, MCODES.luutru, MCODES.taikhoan, 
         MCODES.bcth, MCODES.bcth_bc.dt
    ]],

    // TTPC
    ['donvi.ttpc.chuyenvien', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.ttpc, MCODES.luutru, MCODES.tienich, MCODES.taikhoan,
    ]],
    ['donvi.ttpc.lanhdaophong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.ttpc, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
    ['donvi.ttpc.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.ttpc, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],

    // TDKT
    ['donvi.tdkt.chuyenvien', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.tdkt, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
    ['donvi.tdkt.lanhdaophong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.tdkt, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
    ['donvi.tdkt.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.tdkt, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],

    // KHCN
    ['donvi.khcn.chuyenvien', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.khcn, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
    ['donvi.khcn.lanhdaophong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.khcn, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
    ['donvi.khcn.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.khcn, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],

    // QLDAQT
    ['donvi.qldaqt.chuyenvien', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.qldaqt, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
    ['donvi.qldaqt.lanhdaophong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.qldaqt, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
    ['donvi.qldaqt.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.qldaqt, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],

    // KHDT
    ['donvi.khdt.lanhdaophong', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.khdt, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
    ['donvi.khdt.lanhdaodonvi', [
        MCODES.dashboard, MCODES.bcth, MCODES.bcth_bc.khdt, MCODES.luutru, MCODES.tienich, MCODES.taikhoan
    ]],
]);

// FUNCTIONS
export function menu_items_check_for_role(roles: string[]) {
    let menu_items: Set<string> = new Set();
    roles.forEach((role: string) => {
        let menu_for_role = MENU_ITEMS_ROLES.get(role);
        if (!Array.isArray(menu_for_role)) return;
        menu_for_role.forEach((item: string) => menu_items.add(item));
    });
    return [...menu_items];
}
