// CONSTABCE
export const BC_CTSV_CODES = {
    tt_bao_cao: {
        ban_thao: 'ban_thao', // Bản thảo
        trinh_lanh_dao: 'trinh_lanh_dao', // Trình lãnh đạo đơn vị
        yeu_cau_sua: 'yeu_cau_sua', // Yêu cầu sửa
        da_nop: 'da_nop', // Đã nộp báo cáo
        da_huy: 'da_huy', // Đã hủy
        da_duyet: 'da_duyet', // Đã duyệt
    },
    actions: {
        checked: 'checked', // Xem
        xem: 'xem', // Xem
        them: 'them', // Thêm
        tao_moi: 'tao_moi', // Tạo mới từ báo cáo cũ
        xoa: 'xoa', // Xóa
        sua: 'sua', // Sửa
        trinh_lanh_dao: 'trinh_lanh_dao', // Trình lãnh đạo đơn vị
        yeu_cau_sua: 'yeu_cau_sua', // Yêu cầu sửa
        nop_bao_cao: 'nop_bao_cao', // Nộp báo cáo
    },
};

// ROLES
export const BCTH_BCCTSV_ROLES: Map<string, any> = new Map([
    [
        'donvi.ctsv.chuyenvien',
        {
            query: Object.values(BC_CTSV_CODES.tt_bao_cao),
            global_actions: [BC_CTSV_CODES.actions.them],
            // prettier-ignore
            actions: {
                ban_thao: [BC_CTSV_CODES.actions.tao_moi, BC_CTSV_CODES.actions.xoa, BC_CTSV_CODES.actions.sua],
                yeu_cau_sua: [BC_CTSV_CODES.actions.tao_moi, BC_CTSV_CODES.actions.xoa, BC_CTSV_CODES.actions.sua],
                trinh_lanh_dao: [BC_CTSV_CODES.actions.tao_moi],
                da_nop: [BC_CTSV_CODES.actions.tao_moi],
                da_huy: [BC_CTSV_CODES.actions.tao_moi,],
                da_duyet: [BC_CTSV_CODES.actions.tao_moi,],
            },
        },
    ],
    [
        'donvi.ctsv.lanhdaophong',
        {
            query: Object.values(BC_CTSV_CODES.tt_bao_cao),
            global_actions: [BC_CTSV_CODES.actions.them],
            // prettier-ignore
            actions: {
                ban_thao: [BC_CTSV_CODES.actions.checked, BC_CTSV_CODES.actions.tao_moi, BC_CTSV_CODES.actions.xoa, BC_CTSV_CODES.actions.sua, BC_CTSV_CODES.actions.trinh_lanh_dao],
                yeu_cau_sua: [BC_CTSV_CODES.actions.tao_moi, BC_CTSV_CODES.actions.xoa, BC_CTSV_CODES.actions.sua, BC_CTSV_CODES.actions.trinh_lanh_dao],
                trinh_lanh_dao: [BC_CTSV_CODES.actions.tao_moi,],
                da_nop: [BC_CTSV_CODES.actions.tao_moi,],
                da_huy: [BC_CTSV_CODES.actions.tao_moi,],
                da_duyet: [BC_CTSV_CODES.actions.tao_moi,],
            },
        },
    ],
    [
        'donvi.ctsv.lanhdaodonvi',
        {
            // prettier-ignore
            query: [BC_CTSV_CODES.tt_bao_cao.da_duyet, BC_CTSV_CODES.tt_bao_cao.da_huy, BC_CTSV_CODES.tt_bao_cao.da_nop, BC_CTSV_CODES.tt_bao_cao.trinh_lanh_dao, BC_CTSV_CODES.tt_bao_cao.yeu_cau_sua],
            global_actions: [],
            // prettier-ignore
            actions: {
                ban_thao: [],
                yeu_cau_sua: [],
                trinh_lanh_dao: [BC_CTSV_CODES.actions.checked, BC_CTSV_CODES.actions.nop_bao_cao, BC_CTSV_CODES.actions.yeu_cau_sua],
                da_nop: [],
                da_huy: [],
                da_duyet: [],
            },
            
        },
    ],
]);

// FUNCTIONS
export function bcth_ctsv_check_for_role(roles: string[]) {
    let queries: Set<string> = new Set();
    let actions = {
        ban_thao: new Set(),
        trinh_lanh_dao: new Set(),
        yeu_cau_sua: new Set(),
        da_nop: new Set(),
        da_huy: new Set(),
        da_duyet: new Set(),
    };
    let global_actions: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = BCTH_BCCTSV_ROLES.get(role);
        if (!role_info) return;
        role_info.query.forEach((item: string) => queries.add(item));
        role_info.actions.ban_thao.forEach((item: string) => actions.ban_thao.add(item));
        role_info.actions.trinh_lanh_dao.forEach((item: string) => actions.trinh_lanh_dao.add(item));
        role_info.actions.yeu_cau_sua.forEach((item: string) => actions.yeu_cau_sua.add(item));
        role_info.actions.da_nop.forEach((item: string) => actions.da_nop.add(item));
        role_info.actions.da_huy.forEach((item: string) => actions.da_huy.add(item));
        role_info.actions.da_duyet.forEach((item: string) => actions.da_duyet.add(item));
        role_info.global_actions.forEach((item: string) => global_actions.add(item));
    });

    return {
        query: [...queries],
        actions: {
            ban_thao: [...actions.ban_thao],
            trinh_lanh_dao: [...actions.trinh_lanh_dao],
            yeu_cau_sua: [...actions.yeu_cau_sua],
            da_nop: [...actions.da_nop],
            da_huy: [...actions.da_huy],
            da_duyet: [...actions.da_duyet],
        },
        global_actions: [...global_actions],
    };
}
