// DASHBOARD ELEMENTS
export const DASHBOARD_ELEMENTS = {
    TOP_CARD: 'dashboard-top-card',
    CTSV_TOP_CARD: 'dashboard-ctsv-top-card',
    CTSV_LDDV_TOP_CARD: 'dashboard-ctsv-lddv-top-card',
    KHAO_SAT_TOP_CARD: 'dashboard-khao-sat-top-card',
    KHTC_HNTC_TOP_CARD: 'dashboard-khtc-hntc-top-card',
    KHTC_BCTC_TOP_CARD: 'dashboard-khtc-bctc-top-card',
    KHTC_BCTT99_TOP_CARD: 'dashboard-khtc-bctt99-top-card',
    TTPC_TOP_CARD: 'dashboard-ttpc-top-card',
    DNPTDA_TOP_CARD: 'dashboard-dnptda-top-card',
    KHCN_TOP_CARD: 'dashboard-khcn-top-card',
    QLDAQT_TOP_CARD: 'dashboard-qldaqt-top-card',
    TDKT_TOP_CARD: 'dashboard-tdkt-top-card',
    BCQT_TOP_CARD: 'dashboard-bcqt-top-card',
    BCQTR_TOP_CARD: 'dashboard-bcqtr-top-card',
    TCCB_TOP_CARD: 'dashboard-tccb-top-card',
    TAB_LEARNER: 'dashboard-tab-learner',
    TAB_STUDENT: 'dashboard-tab-student',
    TAB_STAFF: 'dashboard-tab-staff',
};

// ROLES
export const DASHBOARD_ROLES: Map<string, any> = new Map([
    [
        'admin',
        {
            elements: [
                DASHBOARD_ELEMENTS.TOP_CARD,
                DASHBOARD_ELEMENTS.TAB_LEARNER,
                DASHBOARD_ELEMENTS.TAB_STUDENT,
                DASHBOARD_ELEMENTS.TAB_STAFF,
            ],
        },
    ],

    // CTSV
    ['donvi.ctsv.chuyenvien', { elements: [DASHBOARD_ELEMENTS.CTSV_TOP_CARD] }],
    ['donvi.ctsv.lanhdaophong', { elements: [DASHBOARD_ELEMENTS.CTSV_TOP_CARD] }],
    ['donvi.ctsv.lanhdaodonvi', { elements: [DASHBOARD_ELEMENTS.CTSV_LDDV_TOP_CARD] }],

    // KHAO SAT
    ['donvi.dt.chuyenvien', { elements: [DASHBOARD_ELEMENTS.KHAO_SAT_TOP_CARD] }],
    ['donvi.dt.lanhdaophong', { elements: [DASHBOARD_ELEMENTS.KHAO_SAT_TOP_CARD] }],
    ['donvi.dt.lanhdaodonvi', { elements: [DASHBOARD_ELEMENTS.KHAO_SAT_TOP_CARD] }],

    // KHTC
    [
        'donvi.khtc.hntc.ketoanvien',
        {
            elements: [
                DASHBOARD_ELEMENTS.KHTC_HNTC_TOP_CARD,
                DASHBOARD_ELEMENTS.KHTC_BCTC_TOP_CARD,
                DASHBOARD_ELEMENTS.KHTC_BCTT99_TOP_CARD,
                DASHBOARD_ELEMENTS.BCQT_TOP_CARD,
                DASHBOARD_ELEMENTS.BCQTR_TOP_CARD,
            ],
        },
    ],
    [
        'donvi.khtc.hntc.ketoantruong',
        {
            elements: [
                DASHBOARD_ELEMENTS.KHTC_HNTC_TOP_CARD,
                DASHBOARD_ELEMENTS.KHTC_BCTC_TOP_CARD,
                DASHBOARD_ELEMENTS.KHTC_BCTT99_TOP_CARD,
                DASHBOARD_ELEMENTS.BCQT_TOP_CARD,
                DASHBOARD_ELEMENTS.BCQTR_TOP_CARD,
            ],
        },
    ],
    [
        'donvi.khtc.hntc.lanhdaodonvi',
        {
            elements: [
                DASHBOARD_ELEMENTS.KHTC_HNTC_TOP_CARD,
                DASHBOARD_ELEMENTS.KHTC_BCTC_TOP_CARD,
                DASHBOARD_ELEMENTS.KHTC_BCTT99_TOP_CARD,
                DASHBOARD_ELEMENTS.BCQT_TOP_CARD,
                DASHBOARD_ELEMENTS.BCQTR_TOP_CARD,
            ],
        },
    ],

    // TTPC
    ['donvi.ttpc.ketoanvien', { elements: [DASHBOARD_ELEMENTS.TTPC_TOP_CARD] }],
    ['donvi.ttpc.ketoantruong', { elements: [DASHBOARD_ELEMENTS.TTPC_TOP_CARD] }],
    ['donvi.ttpc.lanhdaodonvi', { elements: [DASHBOARD_ELEMENTS.TTPC_TOP_CARD] }],

    // DNPTDA
    ['donvi.dnptda.chuyenvien', { elements: [DASHBOARD_ELEMENTS.DNPTDA_TOP_CARD] }],
    ['donvi.dnptda.lanhdaophong', { elements: [DASHBOARD_ELEMENTS.DNPTDA_TOP_CARD] }],
    ['donvi.dnptda.lanhdaodonvi', { elements: [DASHBOARD_ELEMENTS.DNPTDA_TOP_CARD] }],

    // KHCN
    ['donvi.khcn.chuyenvien', { elements: [DASHBOARD_ELEMENTS.KHCN_TOP_CARD] }],
    ['donvi.khcn.lanhdaophong', { elements: [DASHBOARD_ELEMENTS.KHCN_TOP_CARD] }],
    ['donvi.khcn.lanhdaodonvi', { elements: [DASHBOARD_ELEMENTS.KHCN_TOP_CARD] }],

    // QLDAQT
    ['donvi.qldaqt.ketoanvien', { elements: [DASHBOARD_ELEMENTS.QLDAQT_TOP_CARD] }],
    ['donvi.qldaqt.ketoantruong', { elements: [DASHBOARD_ELEMENTS.QLDAQT_TOP_CARD] }],
    ['donvi.qldaqt.lanhdaodonvi', { elements: [DASHBOARD_ELEMENTS.QLDAQT_TOP_CARD] }],

    // TDKT
    ['donvi.tdkt.ketoanvien', { elements: [DASHBOARD_ELEMENTS.TDKT_TOP_CARD] }],
    ['donvi.tdkt.ketoantruong', { elements: [DASHBOARD_ELEMENTS.TDKT_TOP_CARD] }],
    ['donvi.tdkt.lanhdaodonvi', { elements: [DASHBOARD_ELEMENTS.TDKT_TOP_CARD] }],

    // TCCB
    ['donvi.tccb.ketoanvien', { elements: [DASHBOARD_ELEMENTS.TCCB_TOP_CARD, DASHBOARD_ELEMENTS.TAB_STAFF] }],
    ['donvi.tccb.ketoantruong', { elements: [DASHBOARD_ELEMENTS.TCCB_TOP_CARD, DASHBOARD_ELEMENTS.TAB_STAFF] }],
    ['donvi.tccb.lanhdaodonvi', { elements: [DASHBOARD_ELEMENTS.TCCB_TOP_CARD, DASHBOARD_ELEMENTS.TAB_STAFF] }],
]);

// FUNCTIONS
export function dashboard_check_for_role(roles: string[]) {
    let elements: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = DASHBOARD_ROLES.get(role);
        if (!role_info) return;
        role_info.elements.forEach((item: string) => elements.add(item));
    });

    return {
        elements: [...elements],
    };
}
