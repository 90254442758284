import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { commonRequestHandle } from 'src/app/utils/stop-await';
import { TranslateService } from '@ngx-translate/core';

export const LOAI_BAO_CAO = { DINH_KY: 'dinh_ky', DOT_XUAT: 'dot_xuat' };
export const TRANG_THAI_BAO_CAO = {
    DA_NOP: 'nop_bao_cao',
    DA_LUU: 'luu_bao_cao',
    MO_LAI: 'mo_lai',
    YEU_CAU_SUA: 'yeu_cau_sua',
};
export const MAX_BAO_CAO_FILE_SIZE = 5 * 1024 * 1024;

@Injectable({ providedIn: 'root' })
export class BaoCaoDataService {
    private _data: Map<string, any> = new Map();
    private _dataSub: Subject<Map<string, any>> = new Subject();

    public get data(): Map<string, any> {
        return this._data;
    }
    public set data(value: Map<string, any>) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<Map<string, any>> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService,
        private translate: TranslateService
    ) {}

    async fetch(ten_bao_cao: string) {
        if (!ten_bao_cao) return;

        let result = await commonRequestHandle(
            this.translate,
            () =>
                this.httpClient
                    .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/bao-cao-data/get/' + ten_bao_cao, {
                        headers: { apikey: this.accountService.apikey },
                    })
                    .toPromise(),
            { not_show_error: true }
        );

        if (!result || result.status == 'success') {
            this._data.set(ten_bao_cao, result.data);
            return;
        }

        this._data.set(ten_bao_cao, {});
    }

    async getIfNotExists(ten_bao_cao: string) {
        let data = this._data.get(ten_bao_cao);
        if (data) return data;
        await this.fetch(ten_bao_cao);
        return this._data.get(ten_bao_cao);
    }
}
