import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class FileInternalSendService {
    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private accountService: AccountService
    ) {}

    async uploadFile(file: any, display_name: any, file_internal_id: any = undefined): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getMetadata();

            let formData = new FormData();
            formData.append('file', file);
            formData.append('ma_don_vi', authMetadata.ma_don_vi);
            formData.append('mat_khau_don_vi', authMetadata.mat_khau_don_vi);
            formData.append('display_name', display_name);

            if (file_internal_id) {
                formData.append('file_internal_id', file_internal_id);
            }

            this.httpClient
                .post<any>(this.hostnameService.VnuisAPI_Stagingv1 + '/file-internal-send/upload-file', formData, {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async removeUploadFile(filename: any, fileInternalId: any = ''): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Stagingv1 + '/file-internal-send/remove-upload-file',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                        filename: filename,
                        file_internal_id: fileInternalId
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async sendFile(data: any): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Stagingv1 + '/file-internal-send/send-file',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                        data: data,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async editDraft(data: any, fileInternalId: any): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Stagingv1 + '/file-internal-send/edit-draft',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                        data: data,
                        file_internal_id: fileInternalId,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async sendById(fileInternalId: any): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Stagingv1 + '/file-internal-send/send-by-id',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                        file_internal_id: fileInternalId,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async deleteDuThaoById(fileInternalId: any): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Stagingv1 + '/file-internal-send/delete-du-thao-by-id',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                        file_internal_id: fileInternalId,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
