import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
    private _language: string = 'vn';
    private subjectPostUpdated: Subject<string> = new Subject<string>();

    public get languageSub(): Subject<string> {
        return this.subjectPostUpdated;
    }
    public get language(): string {
        return this._language;
    }
    public set language(value: string) {
        this._language = value;
        this.subjectPostUpdated.next(this._language);
    }

    getLanguage() {
        return this.language;
    }

    getLanguageUpdateListener() {
        return this.subjectPostUpdated.asObservable();
    }

    setLanguage(newLanguage: string) {
        this._language = newLanguage;
        this.subjectPostUpdated.next(this.getLanguage());
    }
}
