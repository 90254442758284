import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QlvbData } from 'src/app/interface/quan_li_van_ban';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class QlvbVbNhanService {
    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private accountService: AccountService
    ) {}

    async getAllHeader() {
        let metadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/qlvb/vb_nhan/get-all-header',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                        mat_khau_don_vi: metadata.mat_khau_don_vi,
                    },
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async get(id: string) {
        let metadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/qlvb/vb_nhan/get-by-id',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                        mat_khau_don_vi: metadata.mat_khau_don_vi,
                    },
                    id: id
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async marIsRead(id: string) {
        let metadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/qlvb/vb_nhan/mark-as-read',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                        mat_khau_don_vi: metadata.mat_khau_don_vi,
                    },
                    id: id,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async markDaXuLy(id: string) {
        let metadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/qlvb/vb_nhan/mark-da-xu-ly',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                        mat_khau_don_vi: metadata.mat_khau_don_vi,
                    },
                    id: id,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async isHasNew() {
        let metadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/qlvb/vb_nhan/is-has-new',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                        mat_khau_don_vi: metadata.mat_khau_don_vi,
                    },
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }
}
