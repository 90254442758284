import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class BcListKhtcBcqtrDataService {
    private _data: Map<string, any> = new Map();
    private _dataSub: Subject<Map<string, any>> = new Subject();

    public get data(): Map<string, any> {
        return this._data;
    }
    public set data(value: Map<string, any>) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<Map<string, any>> {
        return this._dataSub;
    }
    public get orgs(): any[] {
        return this._data.get('orgs') || [];
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetch() {
        let result: any = await this.httpClient
            .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/khtc-bcqtr/is-have-child', {
                headers: {
                    apikey: this.accountService.apikey,
                },
            })
            .toPromise();

        if (result.status == 'error') {
            this._data.set('orgs', []);
            this.data = this._data;
            return this.data.get('orgs');
        }
        this._data.set('orgs', result.orgs);
        this.data = this._data;
        return this.data.get('orgs');
    }

    async fetchIfNotExists() {
        let orgs = this._data.get('orgs') || [];
        if (Array.isArray(orgs) && orgs.length > 0) return orgs;
        return await this.fetch();
    }
}
