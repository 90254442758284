import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HostnameService {
    get VnuisAPI() {
        return 'https://api.vnuhcm.edu.vn';
    }

    get VnuisAPI_Readv1() {
        return 'https://api.vnuhcm.edu.vn/api/v1/read';
        // return 'http://localhost:5000';
    }

    get VnuisAPI_Stagingv1() {
        return 'https://api.vnuhcm.edu.vn/api/v1/staging';
        // return 'http://localhost:8100';
    }

    get VnuisAPI_BaseData_Read_Staffv1() {
        return 'https://api.vnuhcm.edu.vn/api/v1/basic-data/read';
        // return 'http://localhost:8200';
    }

    get VnuisAPI_BaseData_Staging_Staffv1() {
        return 'https://api.vnuhcm.edu.vn/api/v1/basic-data/staging';
        // return 'http://localhost:8201';
    }

    get VnuisAPI_BaseData_Standard_Staffv1() {
        return 'https://api.vnuhcm.edu.vn/api/v1/basic-data/standard';
        // return 'http://localhost:8202';
    }
}
