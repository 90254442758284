import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/providers/language.service';

@Component({
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorPageForMainComponent implements OnInit, AfterViewInit, OnDestroy {
    // Translate
    private language: string = 'vn';
    private languageSub!: Subscription;

    constructor(private translate: TranslateService, private languageService: LanguageService) {}

    /** INIT  */
    ngOnInit(): void {
        // Translate
        this.language = this.languageService.getLanguage();
        this.translate.use(this.language);
        this.languageSub = this.languageService.getLanguageUpdateListener().subscribe((newLanguage: string) => {
            this.language = newLanguage;
            this.translate.use(this.language);
        });
    }

    ngOnDestroy(): void {
        if (this.languageSub) this.languageSub.unsubscribe();
    }

    async ngAfterViewInit() {}

    /** TABLE EVENTS */

    /** UI FUNCTIONS */

    /** OTHER EVENT */

    /** OTHER FUNCTIONS */
}
