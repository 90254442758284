import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { MENU_ITEMS, MENU_ITEMS_ORDER } from './menu-items.service.utils';
import { MCODES } from './roles.menu.utils';
import { KhtcBctcTT107Service } from '../bao-cao-ban-khtc-bctc/khtc-bctc.service';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
    badge?: BadgeItem[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

@Injectable({ providedIn: 'root' })
export class MenuItems implements OnDestroy {
    private khtcBctcTT107Sub: Subscription;

    private _menuItems: Menu[] = [];
    private _menuItemsSub: Subject<Menu[]> = new Subject();

    private _isShowBctcTT107: boolean = false;
    private _currentItems: string[] = [];

    get Menuitems() {
        return this._menuItems;
    }
    set Menuitems(input: Menu[]) {
        this._menuItems = input;
        this._menuItemsSub.next(this._menuItems);
    }

    get MenuitemsSub() {
        return this._menuItemsSub;
    }

    constructor(
        private accountService: AccountService,
        private hostnameService: HostnameService,
        private khtcBctcTT107Service: KhtcBctcTT107Service
    ) {
        this.khtcBctcTT107Sub = this.khtcBctcTT107Service.dataSub.subscribe((data) => {
            this._isShowBctcTT107 = data.length > 0;
            this.updateDisplay();
        });
    }

    ngOnDestroy() {
        this.khtcBctcTT107Sub.unsubscribe();
    }

    updateReceiverFileInternal(isHasNew: boolean) {
        // if (isHasNew) {
        //     if (this._menuItems && this._menuItems[1] && this._menuItems[1].children)
        //         this._menuItems[1].children[1].badge = [{ type: 'warning', value: 'new' }];
        // } else {
        //     if (this._menuItems && this._menuItems[1] && this._menuItems[1].children)
        //         delete this._menuItems[1].children[1].badge;
        // }
    }

    updateDisplay(items: string[] = this._currentItems) {
        this._currentItems = items;

        let ordered_items = MENU_ITEMS_ORDER.filter((item: string) => items.includes(item));
        let menu_items: any[] = [];
        let bcth_bc_values = Object.values(MCODES.bcth_bc);
        let menu_item_bcth = MENU_ITEMS.get(MCODES.bcth);

        menu_item_bcth.children = [];
        for (let key of ordered_items) {
            if (bcth_bc_values.includes(key)) {
                if (key == MCODES.bcth_bc.khtc_bctc_tt99 && !this._isShowBctcTT107) continue;

                let items = MENU_ITEMS.get(key);
                if (Array.isArray(items)) menu_item_bcth.children = [...menu_item_bcth.children, ...items];
                else menu_item_bcth.children.push(items);
            } else {
                menu_items.push(MENU_ITEMS.get(key));
            }
        }

        this.Menuitems = menu_items;
    }
}
