import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { FileService } from './file.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class GeneralDataConvertService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService,
        private fileService: FileService
    ) {}

    downloadTemplate() {
        window.location.href =
            this.hostnameService.VnuisAPI_Readv1 + '/masterlist-convert/download-template.xlsx?apikey=' +
            this.accountService.apikey;
    }

    async convert(file: any): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getMetadata();

            let formData = new FormData();
            formData.append('file', file);
            formData.append('ma_don_vi', authMetadata.ma_don_vi);
            formData.append('mat_khau_don_vi', authMetadata.mat_khau_don_vi);

            this.httpClient
                .post<any>(this.hostnameService.VnuisAPI_Readv1 + '/masterlist-convert/upload-convert', formData, {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getNewestID(limit: number = 1) {
        let authMetadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/masterlist-convert/get-newest-id',
                {
                    metadata: {
                        ma_don_vi: authMetadata.ma_don_vi,
                        mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                    },
                    limit: limit,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async getStatusByID(id: number = 1) {
        let authMetadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/masterlist-convert/get-status-by-id',
                {
                    metadata: {
                        ma_don_vi: authMetadata.ma_don_vi,
                        mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                    },
                    id: id,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async getResult() {
        let authMetadata = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/masterlist-convert/get-result',
                {
                    metadata: {
                        ma_don_vi: authMetadata.ma_don_vi,
                        mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                    },
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async getLastestProcessedFileStat() {
        let authMetadata = this.accountService.getMetadata();
        let filename = 'output-convert-master_list_' + authMetadata.ma_don_vi + '.xlsx';
        return this.fileService.getStat(filename, 'files-downloaded');
    }
}
