import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = { state: 'dashboards', name: 'Dashboards', type: 'link', icon: 'av_timer' };

export const MENU_ITEMS_QLVB = {
    state: 'documents',
    name: 'Documents',
    type: 'sub',
    icon: 'sticky_note_2',
    children: [
        { state: 'documentssend', name: 'Documents Send', type: 'link' },
        {
            state: 'documentsreceive',
            name: 'Documents Receive',
            type: 'link',
        },
        { state: 'documentssave', name: 'Documents Save', type: 'link' },
    ],
};

export const MENU_ITEMS_QLCCTC = {
    state: 'organization',
    name: 'Organizational Chart',
    type: 'link',
    icon: 'subdirectory_arrow_right',
};

export const MENU_ITEMS_QLTSSV = {
    state: 'admissionlearner',
    name: 'Admission Learner',
    type: 'sub',
    icon: 'person_add_alt',
    children: [
        { state: 'admissionlearnerlist', name: 'List of Admission Learner', type: 'link' },
        { state: 'admissionlearnerhistory', name: 'Admission Learner History', type: 'link' },
    ],
};

export const MENU_ITEMS_QLTSHV = {
    state: 'admissionstudent',
    name: 'Admission Student',
    type: 'sub',
    icon: 'group_add',
    children: [
        { state: 'admissionstudentlist', name: 'List of Admission Student', type: 'link' },
        { state: 'admissionstudenthistory', name: 'Admission Student History', type: 'link' },
    ],
};

export const MENU_ITEMS_QLHSCBGV = {
    state: 'staff',
    name: 'Staffs',
    type: 'sub',
    icon: 'work',
    children: [
        { state: 'stafflist', name: 'List of Staff', type: 'link' },
        { state: 'staffhistory', name: 'Staff History', type: 'link' },
        // { state: 'list', name: 'menuitems.staff_list', type: 'link' },
    ],
};

export const MENU_ITEMS_QLHSSV = {
    state: 'learner',
    name: 'Students',
    type: 'sub',
    icon: 'book',
    children: [
        { state: 'learnerlist', name: 'List of Students', type: 'link' },
        { state: 'learnerhistory', name: 'Student History', type: 'link' },
    ],
};

export const MENU_ITEMS_QLHSHV = {
    state: 'student',
    name: 'Graduated Students',
    type: 'sub',
    icon: 'collections_bookmark',
    children: [
        { state: 'studentlist', name: 'List of Graduated Students', type: 'link' },
        { state: 'studenthistory', name: 'Graduated Students History', type: 'link' },
    ],
};

export const MENU_ITEMS_QLTNSV = {
    state: 'graduatelearner',
    name: 'Graduate Learner',
    type: 'sub',
    icon: 'person_remove',
    children: [
        { state: 'graduatelearnerlist', name: 'List of Graduate Learner', type: 'link' },
        { state: 'graduatelearnerhistory', name: 'Graduate Learner History', type: 'link' },
    ],
};

export const MENU_ITEMS_QLTNHV = {
    state: 'graduatestudent',
    name: 'Graduate Student',
    type: 'sub',
    icon: 'group_remove',
    children: [
        { state: 'graduatestudentlist', name: 'List of Graduate Student', type: 'link' },
        { state: 'graduatestudenthistory', name: 'Graduate Student History', type: 'link' },
    ],
};

export const MENU_ITEMS_QLMN = {
    state: 'open-major-management',
    name: 'quan_ly_mo_nganh',
    type: 'sub',
    icon: 'manage_accounts',
    children: [
        { state: 'list-major', name: 'danh_sach_nganh_hoc', type: 'link' },
        { state: 'list-register', name: 'danh_sach_dang_ky_mo_nganh', type: 'link' },
    ],
};

export const MENU_ITEMS_BCTH = {
    state: 'synthetic',
    name: 'Reporting',
    type: 'sub',
    icon: 'library_books',
    children: [],
};

export const MENU_ITEMS_BCTH_BC_VP = { state: 'bao-cao-van-phong', name: 'VNUHCM Office', type: 'link' };

export const MENU_ITEMS_BCTH_BC_TTPC = { state: 'bao-cao-ban-ttpc', name: 'Report TTPC', type: 'link' };

export const MENU_ITEMS_BCTH_BC_TDKT = { state: 'bao-cao-ban-tdkt', name: 'Report TDKT', type: 'link' };

export const MENU_ITEMS_BCTH_BC_TCCB = {
    state: 'bao-cao-tccb',
    name: 'Staff Organization Dept.',
    type: 'link',
};

export const MENU_ITEMS_BCTH_BC_DT = { state: 'reportdt', name: 'Report DT', type: 'link' };

export const MENU_ITEMS_BCTH_BC_KHTC_HNTC = { state: ['bao-cao-hntc', 'bc-list'], name: 'bao_cao_hntc', type: 'link' };

export const MENU_ITEMS_BCTH_BC_KHTC_BCTC = {
    state: ['bao-cao-bctc', 'bc-list'],
    name: 'bao_cao_bctc_107',
    type: 'link',
};
export const MENU_ITEMS_BCTH_BC_KHTC_BCTC_TT99 = {
    state: ['bao-cao-bctc-tt09', 'bc-list'],
    name: 'bao_cao_bctc_99',
    type: 'link',
};

export const MENU_ITEMS_BCTH_BC_KHTC_BCQT = { state: ['bao-cao-bcqt', 'bc-list'], name: 'bao_cao_bcqt', type: 'link' };

export const MENU_ITEMS_BCTH_BC_KHTC_BCQTR = {
    state: ['bao-cao-bcqtr', 'bc-list'],
    name: 'bao_cao_bcqtr',
    type: 'link',
};

export const MENU_ITEMS_BCTH_BC_KHCN = { state: 'bao-cao-ban-khcn', name: 'Report KHCN', type: 'link' };

export const MENU_ITEMS_BCTH_BC_DNPTDA = [
    {
        state: ['bao-cao-ban-dnptda-v2', 'thuc-hien'],
        name: 'bcth_dnptda.menuitems.bao_cao_thuc_hien',
        type: 'subchild-link',
        subchildren: [
            {
                state: 'n1',
                name: 'bcth_dnptda.nhom.thuc-hien_nhom_1_quan_ly_hoc_sinh_sinh_vien_nuoc_ngoai_cong_tac_hoc_tap_tai_co_so',
            },
            {
                state: 'n2',
                name: 'bcth_dnptda.nhom.thuc-hien_nhom_2_quan_ly_giang_vien_chuyen_gia_nuoc_ngoai_lam_viec_giang_day_tai_co_so',
            },
            {
                state: 'n3',
                name: 'bcth_dnptda.nhom.thuc-hien_nhom_3_quan_ly_cong_tac_dao_tao_can_bo_giao_vien_sinh_vien_o_nuoc_ngoai',
            },
            { state: 'n4', name: 'bcth_dnptda.nhom.thuc-hien_nhom_4_quan_ly_doan_vao' },
            { state: 'n5', name: 'bcth_dnptda.nhom.thuc-hien_nhom_5_quan_ly_doan_ra' },
            { state: 'n6', name: 'bcth_dnptda.nhom.thuc-hien_nhom_6_quan_ly_thoa_thuan_hop_tac' },
            { state: 'n7', name: 'bcth_dnptda.nhom.thuc-hien_nhom_7_quan_ly_cac_chuong_trinh_hoc_bong' },
            {
                state: ['n8', 'special-01'],
                name: 'bcth_dnptda.nhom.thuc-hien_nhom_8_quan_ly_chuong_trinh_lien_ket_dao_tao_voi_nuoc_ngoai',
            },
            {
                state: 'n9',
                name: 'bcth_dnptda.nhom.thuc-hien_nhom_9_quan_ly_viec_cap_phep_va_to_chuc_hoi_nghi_hoi_thao_quoc_gia_tai_dhqghcm',
            },
            { state: ['n10', 'special-01'], name: 'bcth_dnptda.nhom.thuc-hien_nhom_10_quan_ly_cac_du_an_quoc_te' },
            { state: ['n11', 'special-01'], name: 'bcth_dnptda.nhom.thuc-hien_nhom_11_quan_ly_cac_du_an_trong_nuoc' },
            {
                state: 'n13',
                name: 'bcth_dnptda.nhom.thuc-hien_nhom_13_quan_ly_tinh_hinh_nhap_canh_va_cu_tru_cua_nguoi_nuoc_ngoai',
            },
        ],
    },
    {
        state: ['bao-cao-ban-dnptda-v2', 'ke-hoach'],
        name: 'bcth_dnptda.menuitems.bao_cao_ke_hoach',
        type: 'subchild-link',
        subchildren: [
            { state: 'n4', name: 'bcth_dnptda.nhom.ke-hoach_nhom_4_quan_ly_doan_vao' },
            { state: 'n5', name: 'bcth_dnptda.nhom.ke-hoach_nhom_5_quan_ly_doan_ra' },
            { state: 'n6', name: 'bcth_dnptda.nhom.ke-hoach_nhom_6_quan_ly_thoa_thuan_hop_tac' },
            {
                state: 'n8',
                name: 'bcth_dnptda.nhom.ke-hoach_nhom_8_quan_ly_chuong_trinh_lien_ket_dao_tao_voi_nuoc_ngoai',
            },
            {
                state: 'n9',
                name: 'bcth_dnptda.nhom.ke-hoach_nhom_9_quan_ly_viec_cap_phep_va_to_chuc_hoi_nghi_hoi_thao_quoc_gia_tai_dhqghcm',
            },
            { state: 'n10', name: 'bcth_dnptda.nhom.ke-hoach_nhom_10_quan_ly_cac_du_an_quoc_te' },
            { state: 'n11', name: 'bcth_dnptda.nhom.ke-hoach_nhom_11_quan_ly_cac_du_an_trong_nuoc' },
        ],
    },
];

export const MENU_ITEMS_BCTH_BC_CTSV = { state: 'bao-cao-ban-ctsv', name: 'Report CTSV', type: 'link' };

export const MENU_ITEMS_BCTH_BC_QLDAQR = { state: 'bao-cao-ban-qldaqt', name: 'Report QLDAQT', type: 'link' };

export const MENU_ITEMS_BCTH_BC_KHDT = { state: 'bao-cao-ban-khdt', name: 'Report KHDT', type: 'link' };

export const MENU_ITEMS_BCTH_BC_BANDT = { state: 'bao-cao-ban-dt', name: 'Report BANDT', type: 'link' };

export const MENU_ITEMS_BCTH_BC_BAN_DU_TOAN = {
    state: 'bao-cao-ban-khtc-du-toan',
    name: 'Report BANDUTOAN',
    type: 'link',
};

export const MENU_ITEMS_LUUTRU = {
    state: 'files',
    name: 'Files',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'filesdownloaded', name: 'Files Downloaded', type: 'link' }],
};

export const MENU_ITEMS_CTDT = {
    state: ['ctdt', 'general'],
    name: 'chuong_trinh_dao_tao',
    type: 'link',
    icon: 'book',
};

export const MENU_ITEMS_TIENICH = {
    state: 'utitlities',
    name: 'Utitlities',
    type: 'sub',
    icon: 'extension',
    children: [
        { state: 'address', name: 'chuan_hoa_dia_chi', type: 'link' },
        { state: 'general', name: 'chuan_hoa_du_lieu_danh_muc', type: 'link' },
        { state: 'standard-basic-data', name: 'chuan_hoa_bang_tap_tin', type: 'link' },
    ],
};

export const MENU_ITEMS_TAIKHOAN = {
    state: 'account',
    name: 'Account',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'editaccount', name: 'Edit Account', type: 'link' }],
};

//
export const MENU_ITEMS_QLHSCBGV_SYNT = {
    state: 'staff-synt',
    name: 'Staffs',
    type: 'sub',
    icon: 'work',
    children: [
        { state: 'list', name: 'List of Staff', type: 'link' },
        { state: 'history', name: 'Staff History', type: 'link' },
    ],
};

export const KHAO_SAT_MENUITEMS = {
    state: 'khao-sat',
    name: 'khao_sat',
    type: 'sub',
    icon: 'format_align_left',
    children: [
        { state: 'danh-sach', name: 'danh_sach_khao_sat', type: 'link' },
        { state: 'quan-ly', name: 'thong_tin_khao_sat', type: 'link' },
        { state: 'thong-ke', name: 'thong_ke_khao_sat', type: 'link' },
    ],
};

//
export const MENU_ITEMS_SYNT_BASE = {
    state: 'synt-base',
    name: 'synt_base',
    type: 'sub',
    icon: 'person_add_alt',
    children: [{ state: 'learner', name: 'learner.routing.learner_list', type: 'link' }],
};

export const MENU_ITEMS_VERSION_STAFF = {
    state: ['version-staff', 'list'],
    name: 'Staff List',
    type: 'link',
    icon: 'work',
    // children: [{ state: 'list', name: 'List of Staff', type: 'link' }],
};

export const MENU_ITEMS_VERSION_LEARNER = {
    state: ['version-learner', 'list'],
    name: 'Learner List',
    type: 'link',
    icon: 'book',
    // children: [{ state: 'list', name: 'List of Learner', type: 'link' }],
};

export const MENU_ITEMS_VERSION_STUDENT = {
    state: ['version-student', 'list'],
    name: 'Student List',
    type: 'link',
    icon: 'collections_bookmark',
    // children: [{ state: 'list', name: 'List of Student', type: 'link' }],
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.qlvb, MENU_ITEMS_QLVB],
    [MCODES.qlcctc, MENU_ITEMS_QLCCTC],
    [MCODES.qltssv, MENU_ITEMS_QLTSSV],
    [MCODES.qltshv, MENU_ITEMS_QLTSHV],
    [MCODES.qlhscbgv, MENU_ITEMS_QLHSCBGV],
    [MCODES.qlhssv, MENU_ITEMS_QLHSSV],
    [MCODES.qlhshv, MENU_ITEMS_QLHSHV],
    [MCODES.qltnsv, MENU_ITEMS_QLTNSV],
    [MCODES.qltnhv, MENU_ITEMS_QLTNHV],
    [MCODES.qlmn, MENU_ITEMS_QLMN],
    [MCODES.bcth, MENU_ITEMS_BCTH],
    [MCODES.bcth_bc.vp, MENU_ITEMS_BCTH_BC_VP],
    [MCODES.bcth_bc.ttpc, MENU_ITEMS_BCTH_BC_TTPC],
    [MCODES.bcth_bc.tdkt, MENU_ITEMS_BCTH_BC_TDKT],
    [MCODES.bcth_bc.tccb, MENU_ITEMS_BCTH_BC_TCCB],
    [MCODES.bcth_bc.dt, MENU_ITEMS_BCTH_BC_BANDT],
    [MCODES.bcth_bc.khtc_hntc, MENU_ITEMS_BCTH_BC_KHTC_HNTC],
    [MCODES.bcth_bc.khtc_bctc, MENU_ITEMS_BCTH_BC_KHTC_BCTC],
    [MCODES.bcth_bc.khtc_bctc_tt99, MENU_ITEMS_BCTH_BC_KHTC_BCTC_TT99],
    [MCODES.bcth_bc.khtc_bcqt, MENU_ITEMS_BCTH_BC_KHTC_BCQT],
    [MCODES.bcth_bc.khtc_bcqtr, MENU_ITEMS_BCTH_BC_KHTC_BCQTR],
    [MCODES.bcth_bc.khtc_bc_du_toan, MENU_ITEMS_BCTH_BC_BAN_DU_TOAN],
    [MCODES.bcth_bc.khcn, MENU_ITEMS_BCTH_BC_KHCN],
    [MCODES.bcth_bc.dnptda, MENU_ITEMS_BCTH_BC_DNPTDA],
    [MCODES.bcth_bc.ctsv, MENU_ITEMS_BCTH_BC_CTSV],
    [MCODES.bcth_bc.qldaqt, MENU_ITEMS_BCTH_BC_QLDAQR],
    [MCODES.bcth_bc.khdt, MENU_ITEMS_BCTH_BC_KHDT],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],

    // [MCODES.qlhscbgv_synt, MENU_ITEMS_QLHSCBGV_SYNT],
    [MCODES.khao_sat, KHAO_SAT_MENUITEMS],

    [MCODES.ctdt, MENU_ITEMS_CTDT],

    //
    [MCODES.synt_base, MENU_ITEMS_SYNT_BASE],
    [MCODES.version_staff, MENU_ITEMS_VERSION_STAFF],
    [MCODES.version_learner, MENU_ITEMS_VERSION_LEARNER],
    [MCODES.version_student, MENU_ITEMS_VERSION_STUDENT],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.qlvb,
    MCODES.qlcctc,
    MCODES.qltssv,
    MCODES.qltshv,

    MCODES.qlhscbgv,
    MCODES.qlhssv,
    MCODES.qlhshv,

    MCODES.version_staff,
    MCODES.version_learner,
    MCODES.version_student,

    MCODES.qltnsv,
    MCODES.qltnhv,
    MCODES.qlmn,
    MCODES.ctdt,
    MCODES.bcth,
    MCODES.bcth_bc.vp,
    MCODES.bcth_bc.ttpc,
    MCODES.bcth_bc.tdkt,
    MCODES.bcth_bc.tccb,
    MCODES.bcth_bc.dt,
    MCODES.bcth_bc.khtc_bcqtr,
    MCODES.bcth_bc.khtc_bctc,
    MCODES.bcth_bc.khtc_bctc_tt99,
    MCODES.bcth_bc.khtc_bcqt,
    MCODES.bcth_bc.khtc_hntc,
    MCODES.bcth_bc.khtc_bc_du_toan,
    MCODES.bcth_bc.khcn,
    MCODES.bcth_bc.dnptda,
    MCODES.bcth_bc.ctsv,
    MCODES.bcth_bc.qldaqt,
    MCODES.bcth_bc.khdt,
    MCODES.khao_sat,
    MCODES.luutru,
    MCODES.tienich,
    MCODES.taikhoan,

    // MCODES.qlhscbgv_synt,
    // MCODES.synt_base,
];
