import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { bandt_ctdt_check_for_role } from './roles.ban-dt.ctdt.utils';
import { bcth_ctsv_check_for_role } from './roles.bcth.ctsv.utils';
import { bcth_dnptda_check_for_role } from './roles.bcth.dnptda.utils';
import { bcth_khcn_check_for_role } from './roles.bcth.khcn.utils';
import { bcth_khtc_bcqt_check_for_role } from './roles.bcth.khtc.bcqt.utils';
import { bcth_khtc_bcqtr_check_for_role } from './roles.bcth.khtc.bcqtr.utils';
import { bcth_khtc_bctc_check_for_role } from './roles.bcth.khtc.bctc.utils';
import { bcth_khtc_hntc_check_for_role } from './roles.bcth.khtc.hntc.utils';
import { bcth_tccb_check_for_role } from './roles.bcth.tccb.utils';
import { bcth_tdkt_check_for_role } from './roles.bcth.tdkt.utils';
import { bcth_ttpc_check_for_role } from './roles.bcth.ttpc.utils';
import { dashboard_check_for_role } from './roles.dashboard.utils';
import { MCODES, menu_items_check_for_role } from './roles.menu.utils';
import { bcth_qldaqt_check_for_role } from './roles.bcth.qldaqt.utils';
import { bcth_khdt_check_for_role } from './roles.bcth.khdt.utils';
import { bcth_dt_check_for_role } from './roles.bcth.dt.utils';
import { bcth_khtc_du_toan_check_for_role } from './roles.bcth.khtc.du_toan.utils';

@Injectable({ providedIn: 'root' })
export class RolesPermissionService {
    private _data: Map<string, any> = new Map();
    private _dataSub: Subject<Map<string, any>> = new Subject();

    get data() {
        return this._data;
    }
    get dataSub() {
        return this._dataSub;
    }

    constructor(
        private accountService: AccountService,
        private hostnameService: HostnameService,
        protected readonly keyCloakService: KeycloakService
    ) {
        this._data.clear();
        this._data.set('roles', []);
        this._data.set('menu_items', [MCODES.tienich, MCODES.taikhoan]);
        this._data.set(MCODES.bcth_bc.ctsv, {});
        this._data.set(MCODES.bcth_bc.tccb, {});
        this._data.set(MCODES.bcth_bc.dnptda, {});
        this._data.set(MCODES.bcth_bc.khtc_hntc, {});
        this._data.set(MCODES.bcth_bc.khtc_bctc, {});
        this._data.set(MCODES.bcth_bc.khtc_bctc_tt99, {});
        this._data.set(MCODES.bcth_bc.khtc_bcqt, {});
        this._data.set(MCODES.bcth_bc.khtc_bcqtr, {});
        this._data.set(MCODES.bcth_bc.khtc_bc_du_toan, {});
        this._data.set(MCODES.bcth_bc.ttpc, {});
        this._data.set(MCODES.bcth_bc.tdkt, {});
        this._data.set(MCODES.bcth_bc.khcn, {});
        this._data.set(MCODES.bcth_bc.qldaqt, {});
        this._data.set(MCODES.bcth_bc.khdt, {});
        this._data.set(MCODES.bcth_bc.dt, {});
        this._data.set(MCODES.dashboard, {});
        this._data.set(MCODES.ctdt, {});
    }

    changeForRole(newRoles: string[]) {
        let currentValues: any = this._data.get('roles');
        if (currentValues.length == newRoles.length && newRoles.every((role: string) => currentValues.includes(role)))
            return;

        this._data.set('roles', newRoles);
        this._data.set('menu_items', menu_items_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.ctsv, bcth_ctsv_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.tccb, bcth_tccb_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.dnptda, bcth_dnptda_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.khtc_hntc, bcth_khtc_hntc_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.khtc_bctc, bcth_khtc_bctc_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.khtc_bctc_tt99, bcth_khtc_bctc_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.khtc_bcqt, bcth_khtc_bcqt_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.khtc_bcqtr, bcth_khtc_bcqtr_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.khtc_bc_du_toan, bcth_khtc_du_toan_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.ttpc, bcth_ttpc_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.tdkt, bcth_tdkt_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.khcn, bcth_khcn_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.qldaqt, bcth_qldaqt_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.khdt, bcth_khdt_check_for_role(newRoles));
        this._data.set(MCODES.bcth_bc.dt, bcth_dt_check_for_role(newRoles));
        this._data.set(MCODES.dashboard, dashboard_check_for_role(newRoles));
        this._data.set(MCODES.ctdt, bandt_ctdt_check_for_role(newRoles));
        this._dataSub.next(this._data);
    }
}
